import React from 'react'

import { ReactTypeformEmbed } from 'react-typeform-embed';

class App extends React.Component {
    render() {
      return <ReactTypeformEmbed url="https://borealgreensystems.typeform.com/to/zS9cEA" />;
    }
  }


export default App